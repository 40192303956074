import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet groc daurat tot ell amb un capell de fins a 3 cm de diàmetre, un poc deprimit al centre i posteriorment quasi umbilicat. És lleugerament viscós i higròfan. Les làmines poden ser d’adnades a escotades i ventrudes. El peu és llarg i recte cap amunt, essent ondulat cap a la base i més gruixut amb restes miceliars. També es poden observar restes d’anell cortiniforme. Les espores són ocre fosc en massa, el·líptiques, ornamentades; de 8-8,5 x 6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      